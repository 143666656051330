import { Log } from "model/Log";

import { BaseResp } from './BaseResp';
import { client } from "./client";
import { downloadExcel } from "./commonApi";
import { LogSearchRequest } from "./request/LogSearchRequest";

export interface LogListResp extends BaseResp {
  totalCount: number;
  list: Log[];
}

export interface LogResp extends BaseResp {
  info: Log;
}

export const getLogList = (req: LogSearchRequest): Promise<LogListResp> => client.get('v1/history/list', { params: req });

export const downloadLogsListExcel = (req: LogSearchRequest) =>
  downloadExcel(req, 'v1/history/excel', '이력목록');



