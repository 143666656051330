import { Link } from 'react-router-dom';
import NoticeDetailComponent from '../components/notice/NoticeDetail';

export default function NoticeDetail() {
  return (
    <div className={'contents'} data-simplebar>
      <nav>
        <ol className={'breadcrumb'}>
          <li className={'breadcrumb-item'}>
            <Link to={'/home'} replace>
              {'Home'}
            </Link>
          </li>
          <li className={'breadcrumb-item'}>
            <Link to={'/board/notice'}>{'사이트 관리'}</Link>
          </li>
          <li className={'breadcrumb-item active'} aria-current={'page'}>
            {'공지사항 상세'}
          </li>
        </ol>
      </nav>
      <NoticeDetailComponent />
    </div>
  );
}
