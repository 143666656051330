import { format } from 'date-fns';

import { LOCKER_STATE, LOCKER_TYPE } from 'types/enumType';

export class LogSearchRequest {
  factoryId: number | null;

  id: number | null;

  lockerId: number | null;

  userId: number | null;

  startDate: string;

  endDate: string;

  type: LOCKER_TYPE | string;

  searchKey: string;

  page: number;

  count: number;

  event: LOCKER_STATE | string;

  constructor(page: number, count: number, factoryId?: number) {
    this.factoryId = factoryId ?? null;
    this.id = null;
    this.lockerId = null;
    this.userId = null;
    this.type = '';
    this.event = ''

    const weekAgoDate = new Date();
    weekAgoDate.setMonth(weekAgoDate.getMonth() - 1);
    this.startDate = format(weekAgoDate, 'yyyy-MM-dd');
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.endDate = format(tomorrow, 'yyyy-MM-dd');

    this.searchKey = '';
    this.page = page;
    this.count = count;
  }
}
