import { Suspense } from 'react';

/* eslint-disable react/require-default-props */
type ImageProps = {
  src: string | undefined;
  className?: string;
  alt: string;
  width?: number;
  height?: number;
};
function Image({ src, className = '', alt, width, height }: ImageProps) {
  if (!src) {
    return null;
  }

  const onError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const e = event;
    e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/default-image.png`;
  };
  return (
    <Suspense>
      <img
        className={className}
        alt={alt}
        src={src}
        onError={onError}
        width={width}
        height={height}
      />
    </Suspense>
  );
}

export default Image;
