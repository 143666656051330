import ReactModal from 'react-modal';

import DashboardInfo from 'model/DashboardInfo';

type DeviceInfoModalProps = {
  info: DashboardInfo | null;
  isOpen: boolean;
  posX: number;
  posY: number;
  onOpenOrderInfo: () => void;
  onClose: () => void;
};

export default function DeviceInfoModal({
  info,
  isOpen,
  posX,
  posY,
  onOpenOrderInfo,
  onClose,
}: DeviceInfoModalProps) {
  const getOrderName = (nameList: string[]) => {
    if (nameList.length === 0) {
      return '';
    }

    return nameList[0];
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          left: posX,
          top: posY,
          right: 'auto',
          bottom: 'auto',
          border: '2px solid #292929',
          padding: '0.5rem',
          color: '#000',
        },
      }}
      onRequestClose={onClose}
      overlayClassName={'ReactModal__Overlay__transparent'}
      id={'tooltip'}
    >
      <div id={'popup-wrap'} className={'tooltip'}>
        <div className={'popup-wrap'}>
          <div className={'popup-cont'}>
            {info && (
              <div className={'tt_tbl side-status-wrap'}>
                <h3 className={'mb-10'}>
                  {'['}
                  {info.code}
                  {'] '}
                  {info.name}
                </h3>
                <table className={'tbl-type-05'}>
                  <tbody>
                    <tr>
                      <th colSpan={2}>{'작업허가서'}</th>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        className={'center underline'}
                        onClick={onOpenOrderInfo}
                      >
                        <b>{getOrderName(info.orderNames ?? [])}</b>
                      </td>
                    </tr>
                    <tr>
                      <th>{'부착'}</th>
                      <th>{'제거'}</th>
                    </tr>
                    <tr>
                      <td className={'center'}>{info.lockLocked}</td>
                      <td className={'center'}>{info.lockUnlocked}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
