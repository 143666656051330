import { recoilPersist } from "recoil-persist";

import { CustomerSearchRequest } from "api/request/CustomerSearchRequest";
import { InquirySearchRequest } from "api/request/InquirySearchRequest";
import { LogSearchRequest } from "api/request/LogSearchRequest";
import { LotoOrderSearchRequest } from "api/request/LotoOrderSearchRequest";
import { NoticeSearchRequest } from "api/request/NoticeSearchRequest";
import { UserSearchRequest } from "api/request/UserSearchRequest";
import { atom } from "recoil";
import { ROLE_TYPE } from "types/enumType";
import { encryptString } from "util/encryptUtil";

type UserInfo = {
  customerId: string;
  customerName: string;
  factoryId: string;
  factoryName: string;
  areaUseYn: string;
  role: string;
  name: string;
  latestDt: string;
  isAuthenticated: boolean;
  userId: string;
}

export type HistorySearchInfo = {
  location: string;
  search: LotoOrderSearchRequest | LogSearchRequest | UserSearchRequest | CustomerSearchRequest | NoticeSearchRequest | InquirySearchRequest | null;
}

export const userInfoPersist = recoilPersist({
  key: 'userInfo',
  storage: sessionStorage,
  converter: JSON
})

export const userInfoState = atom<UserInfo>({
  key: 'userInfoState',
  default: {
    customerId: '',
    customerName: '',
    factoryId: '',
    factoryName: '',
    areaUseYn: 'N',
    role: encryptString(ROLE_TYPE.USER),
    name: '',
    latestDt: '',
    isAuthenticated: false,
    userId: ''
  },
  effects_UNSTABLE: [userInfoPersist.persistAtom]
})

export const searchInfoState = atom<HistorySearchInfo>({
  key: 'historySearchState',
  default: {
    location: '',
    search: null
  }
})