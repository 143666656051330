import { createBrowserRouter, Navigate } from 'react-router-dom';

import Communication from 'pages/Communication';
import Default from 'pages/Default';
import DeviceInfoPopup from 'pages/DeviceInfoPopup';
import Mypage from 'pages/Mypage';
import NoticeDetail from 'pages/NoticeDetail';
import SignIn from 'pages/SignIn';
import { retryLazy } from 'util/lazyUtil';

import AuthGuard from './AuthGuard';
import ProtectedRoute from './ProtectedRoute';

const Home = retryLazy(() => import('pages/Home'));
const Admin = retryLazy(() => import('pages/Admin'));
const AdminDetail = retryLazy(() => import('pages/AdminDetail'));
const Conclusion = retryLazy(() => import('pages/Conclusion'));
const LockerList = retryLazy(() => import('pages/LockerList'));
const LockerDetail = retryLazy(() => import('pages/LockerDetail'));
const UserList = retryLazy(() => import('pages/UserList'));
const UserDetail = retryLazy(() => import('pages/UserDetail'));
const LotoOrder = retryLazy(() => import('pages/LotoOrder'));
const AuthConfirmPassword = retryLazy(
  () => import('pages/AuthConfirmPassword'),
);

const DeviceList = retryLazy(() => import('pages/DeviceList'));
const AreaPosition = retryLazy(() => import('pages/AreaPosition'));
const DevicePosition = retryLazy(() => import('pages/DevicePosition'));

const InquiryList = retryLazy(() => import('pages/InquiryList'));
const InquiryDetail = retryLazy(() => import('pages/InquiryDetail'));
const Notice = retryLazy(() => import('pages/Notice'));
const CustomerList = retryLazy(() => import('pages/CustomerList'));
const CustomerDetail = retryLazy(() => import('pages/CustomerDetail'));

const routes = createBrowserRouter([
  {
    path: '/sign-in',
    element: (
      <AuthGuard>
        <SignIn />
      </AuthGuard>
    ),
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <Default />
      </AuthGuard>
    ),
    children: [
      { path: '/home', element: <Home /> },
      { path: '/auth-confirm', element: <AuthConfirmPassword /> },
      {
        path: '/mypage',
        element: (
          <ProtectedRoute>
            <Mypage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/lock-status',
        children: [
          {
            path: 'order',
            element: <LotoOrder />,
          },
          {
            path: 'conclusion',
            element: <Conclusion />,
          },
          {
            path: 'communication',
            element: <Communication />,
          },
        ],
      },
      {
        path: '/locker',
        children: [
          {
            path: '',
            element: <LockerList />,
          },
          {
            path: ':lockerId',
            element: <LockerDetail />,
          },
        ],
      },
      {
        path: '/device',
        children: [
          {
            path: 'setting',
            element: <DeviceList />,
          },
          {
            path: 'area-position',
            element: <AreaPosition />,
          },
          {
            path: 'device-position',
            element: <DevicePosition />,
          },
        ],
      },
      {
        path: '/users',
        children: [
          {
            path: 'user',
            children: [
              {
                path: '',
                element: <UserList />,
              },
              {
                path: ':userId',
                element: <UserDetail />,
              },
            ],
          },
          {
            path: 'user',
            children: [
              {
                path: '',
                element: <UserList />,
              },
              {
                path: ':userId',
                element: <UserDetail />,
              },
            ],
          },
          {
            path: 'customer',
            children: [
              {
                path: '',
                element: <CustomerList />,
              },
              {
                path: ':customerId',
                element: <CustomerDetail />,
              },
            ],
          },
          {
            path: 'admin',
            children: [
              {
                path: '',
                element: <Admin />,
              },
              {
                path: ':userId',
                element: <AdminDetail />,
              },
            ],
          },
        ],
      },
      {
        path: '/board',
        children: [
          {
            path: 'notice',
            children: [
              {
                path: '',
                element: <Notice />,
              },
              {
                path: ':noticeId',
                element: <NoticeDetail />,
              },
            ],
          },
          {
            path: 'inquiry',
            children: [
              {
                path: '',
                element: <InquiryList />,
              },
              {
                path: ':inquiryId',
                element: <InquiryDetail />,
              },
            ],
          },
        ],
      },
    ],
  },
  { path: '/popup/area-map/:id', element: <DeviceInfoPopup /> },
  { path: '/*', element: <Navigate to={'/home'} replace /> },
]);

export default routes;
