import { ReactNode, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

type ProtectedRouteProps = {
  children: ReactNode;
};

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { state, pathname } = useLocation();

  if (!state?.isAuthenticated) {
    return <Navigate to={'/auth-confirm'} state={{ pathname }} />;
  }

  return <Suspense>{children}</Suspense>;
}
