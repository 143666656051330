/* eslint-disable no-param-reassign */
import { fabric } from 'fabric';

import DashboardInfo from 'model/DashboardInfo';
import { Diagram } from 'model/Diagram';
import { Equipment } from "model/Equipment";
import { Position } from 'model/Position';
import { DEVICE_TYPE } from "types/enumType";

import { getLockerImgByConclusion, getStrokeColor, ZONE_SQUARE_IMAGE } from './commonUtil';

export const getMinRatio = (section: Diagram, img: Diagram): number => {
  const widthRatio = section.width / img.width;
  const heightRatio = section.height / img.height;

  return Math.min(widthRatio, heightRatio);
}

export const getDeviceImgOption = (oImg: fabric.Image, device: Equipment | Position, ratio: number, centerRect: { left: number, top: number }, selectable: boolean = false) => {
  oImg.setOptions({
    left: (device.left ?? 1000) * ratio + centerRect.left,
    top: (device.top ?? 0) * ratio + centerRect.top,
    width: 50,
    height: 50,
    name: `${DEVICE_TYPE.DEVICE}/${device.id}`,
    strokeWidth: 0,
    originX: 'left',
    originY: 'top',
    selectable
  });
  oImg.scaleX = ((device.width ?? 50) / 50) * ratio;
  oImg.scaleY = ((device.height ?? 50) / 50) * ratio;

  return oImg;
}

export const createRectByEquipmentAndRatio = (
  info: Equipment,
  type: DEVICE_TYPE,
  ratio: number,
  selectable: boolean,
  centerRect: { left: number, top: number },
  fill: string = 'rgba(255, 255, 255, 0.0)',
) =>
  new fabric.Rect({
    left: (info.left ?? 500) * ratio + centerRect.left,
    top: (info.top ?? 0) * ratio + centerRect.top,
    width: (info.width ?? 100) * ratio,
    height: (info.height ?? 100) * ratio,
    fill,
    stroke: getStrokeColor(type),
    strokeWidth: 2,
    strokeUniform: true,
    selectable,
    originX: 'left',
    originY: 'top',
    name: `${type}/${info.id}`,
  });

export const createNewRectByEquipment = (info: Equipment, type: DEVICE_TYPE, ratio: number, centerRect: { left: number, top: number }) =>
  new fabric.Rect({
    left: centerRect.left,
    top: centerRect.top,
    width: 100 * ratio,
    height: 100 * ratio,
    fill: 'rgba(255, 255, 255, 0.0)',
    stroke: getStrokeColor(type),
    strokeWidth: 2,
    strokeUniform: true,
    selectable: true,
    name: `${type}/${info.id}`,
  });

export const drawActiveAreaRectList = (
  canvas: fabric.Canvas,
  areas: DashboardInfo[],
  scale: number,
  centerRect: { left: number, top: number }
) => {
  areas.forEach(area => {
    const rect = createRectByEquipmentAndRatio(
      area,
      DEVICE_TYPE.AREA,
      scale,
      false,
      centerRect
    );
    if (area.orderTotal > 0) {
      fabric.Image.fromURL(ZONE_SQUARE_IMAGE, oImg => {
        const { x, y } = rect.getCenterPoint();
        oImg.scale(scale);
        oImg.setOptions({
          left: x,
          top: y,
          width: 25,
          height: 25,
          originX: 'center',
          originY: 'center',
        });
        const group = new fabric.Group([rect, oImg], {
          selectable: false,
          name: `${DEVICE_TYPE.AREA}/${area.id}/active`,
        });
        canvas.add(group);
      });
    } else {
      canvas.add(rect);
    }
  });
};

export const drawAreaRectList = (
  canvas: fabric.Canvas,
  areas: DashboardInfo[],
  scale: number,
  centerRect: { left: number, top: number }
) => {
  areas.forEach(area => {
    const rect = createRectByEquipmentAndRatio(
      area,
      DEVICE_TYPE.AREA,
      scale,
      false,
      centerRect
    );
    if (area.orderTotal > 0) {
      fabric.Image.fromURL(ZONE_SQUARE_IMAGE, oImg => {
        const { x, y } = rect.getCenterPoint();
        oImg.scale(scale);
        oImg.setOptions({
          left: x,
          top: y,
          width: 50,
          height: 50,
          originX: 'center',
          originY: 'center',
        });
        const group = new fabric.Group([rect, oImg], {
          selectable: false,
          name: `${DEVICE_TYPE.AREA}/${area.id}/active`,
        });
        canvas.add(group);
      });
    } else {
      canvas.add(rect);
    }
  });
};

export const drawActiveDeviceRectList = (
  canvas: fabric.Canvas,
  devices: DashboardInfo[],
  ratio: number,
  centerRect: { left: number, top: number }
) => {
  devices
    .filter(device => device.width !== 0 && device.orderTotal > 0)
    .forEach(device => {
      const {
        lockLocked,
        lockUnlocked,
      } = device;
      const deviceImage = getLockerImgByConclusion(lockLocked, lockUnlocked);
      fabric.Image.fromURL(deviceImage, oImg => {
        canvas.add(getDeviceImgOption(oImg, device, ratio, centerRect));
      });
    });
};

export const enablePanningAndZoom = (canvas: fabric.Canvas, useAlt: boolean) => {
  let isDragging = false;
  let lastX: number = 0;
  let lastY: number = 0;

  canvas.on('mouse:down', event => {
    if (event.e.altKey || !useAlt) {
      lastX = event.e.clientX;
      lastY = event.e.clientY;
      isDragging = true;
    }
  });

  canvas.on('mouse:move', event => {
    if (!isDragging) return;

    const pointer = canvas.getPointer(event.e);
    lastX = pointer.x;
    lastY = pointer.y;

    const delta = new fabric.Point(event.e.movementX, event.e.movementY);
    canvas?.relativePan(delta);
  });

  canvas.on('mouse:up', () => {
    isDragging = false;
  });

  canvas.on('mouse:wheel', opt => {
    // delta는 마우스 휠의 회전량
    const delta = opt.e.deltaY;
    let zoom = canvas.getZoom();
    // 마우스 휠의 zoom을 부드럽게 하기 위해서 1에 가깝게 진행
    zoom *= 0.999 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom <= 1) zoom = 1;
    canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();

    // zoome이 1로 돌아왔을 때 원래자리로 오게
    const vpt = canvas.viewportTransform!;
    const canvasW = canvas.getWidth();
    const canvasH = canvas.getHeight();
    if (vpt && zoom === 1) {
      if (zoom < 400 / canvasW) {
        vpt[4] = 200 - (canvasW * zoom) / 2;
        vpt[5] = 200 - (canvasW * zoom) / 2;
      } else {
        if (vpt[4] >= 0) {
          vpt[4] = 0;
        } else if (vpt[4] < canvas.getWidth() - canvasW * zoom) {
          vpt[4] = canvas.getWidth() - canvasW * zoom;
        }
        if (vpt[5] >= 0) {
          vpt[5] = 0;
        } else if (vpt[5] < canvas.getHeight() - canvasH * zoom) {
          vpt[5] = canvas.getHeight() - canvasH * zoom;
        }
      }
    }
  });

  return () => {
    canvas.dispose();
  };
}