type ButtonProps = {
  name: string;
  className: string;
  onClick: () => void;
};

export function Button({ name, onClick, className }: ButtonProps) {
  return (
    <button type={'button'} className={className} onClick={onClick}>
      {name}
    </button>
  );
}
