import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { userInfoKeys } from 'api/queryKeys/queryKeys';
import { signOut } from 'api/sessionApi';
import {
  getMyInfo,
  MyInfoFormData,
  PassFormData,
  useChangePasswordMutation,
  useRegisterSignImgMutation,
  useUpdateMyInfoMutation,
} from 'api/userApi';
import { useResetRecoilState } from 'recoil';
import { userInfoState } from 'recoil/user/atom';

import PasswordModal from './PasswordModal';
import SignModal from './SignModal';
import UserInfoForm from './UserInfoForm';

export type ModalPropType = {
  isOpen: boolean;
  onSubmit: (formData: PassFormData) => void;
};

function Mypage() {
  const queryClient = useQueryClient();

  const { data, refetch } = useQuery({
    queryKey: userInfoKeys.details(),
    queryFn: () => getMyInfo(),
  });

  const [isPasswordModalOpen, setIsPasswordModal] = useState(false);
  const [isSignModalOpen, setIsSignModal] = useState(false);

  const onPasswordModalOpen = () => {
    setIsPasswordModal(true);
  };
  const onPasswordModalClose = () => {
    setIsPasswordModal(false);
  };
  const onSignModalOpen = () => {
    setIsSignModal(true);
  };
  const onSignModalClose = () => {
    setIsSignModal(false);
  };

  const resetUserInfo = useResetRecoilState(userInfoState);
  const navigate = useNavigate();
  const handleSignOut = async () => {
    await signOut();
    resetUserInfo();
    queryClient.clear();
    sessionStorage.clear();
    navigate('/sign-in');
  };
  const changePasswordMutation = useChangePasswordMutation();
  const handleSubmit = (formData: PassFormData) => {
    changePasswordMutation.mutate(formData, {
      onSuccess: (response) => {
        if (response.code === 200) {
          onPasswordModalClose();
          alert('비밀번호가 변경되었습니다 다시 로그인해주세요');

          handleSignOut();
        } else {
          alert(response.message);
        }
      },
    });
  };
  const editUserMutate = useUpdateMyInfoMutation();
  const handleUserInfoSubmit: SubmitHandler<MyInfoFormData> = (
    formData: MyInfoFormData,
  ) => {
    editUserMutate.mutate(formData, {
      onSuccess: () => {
        refetch();
        alert('내정보가 수정 되었습니다.');
      },
    });
  };
  const registerSignImgMutate = useRegisterSignImgMutation();
  const handleSignImgSubmit = (signImgUrl: string) => {
    registerSignImgMutate.mutate(signImgUrl, {
      onSuccess: (response) => {
        if (response.code === 200) {
          onSignModalClose();
          refetch();
        } else {
          alert(response.message);
        }
      },
    });
  };

  /**
   * 1. 등록 버튼 누르면 모달 오픈
   * 2. react signature
   * 3. 저장 누르면 전송 후 refetch
   */

  return (
    <section className={'mypage'}>
      <UserInfoForm
        item={data?.info}
        onPasswordModalClick={onPasswordModalOpen}
        onSignModalClick={onSignModalOpen}
        onSubmit={handleUserInfoSubmit}
      />
      <PasswordModal
        isOpen={isPasswordModalOpen}
        onClose={onPasswordModalClose}
        onSubmit={handleSubmit}
      />
      <SignModal
        isOpen={isSignModalOpen}
        onClose={onSignModalClose}
        onSubmit={handleSignImgSubmit}
      />
    </section>
  );
}

export default Mypage;
