import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { ChangeHistory } from 'model/ChangeHistory';
import { Customer } from 'model/Customer';
import { Equipment } from 'model/Equipment';
import { Factory } from 'model/Factory';
import { YN_TYPE } from 'types/enumType';

import { BaseResp } from './BaseResp';
import { client } from './client';
import { downloadExcel } from './commonApi';
import { CustomerSearchRequest } from './request/CustomerSearchRequest';
import { LogSearchRequest } from './request/LogSearchRequest';

export interface CustomerFormData {
  customerId: number;
  code: string;
  name: string;
  factoryName?: string;
  areaUseYn?: YN_TYPE | string;
  userAccount: string;
  userName: string;
  userEmail: string;
  userPhone: string;
  activate: YN_TYPE | string;
  resetPass?: boolean | string;
  isDoubleCheck?: boolean;
}

export interface FactoryFormData {
  customerId: number,
  factoryId: number,
  name: string;
  areaUseYn?: YN_TYPE | string;
}

export interface FactoryImageFormData {
  factoryId: number,
  image: string;
}

export interface CustomerListResp extends BaseResp {
  totalCount: number;
  list: Customer[];
}

export interface CustomerChangeHistoryResp extends BaseResp {
  totalCount: number;
  list: ChangeHistory[];
}

export interface CustomerResp extends BaseResp {
  info: Customer;
}

export interface FactoryListResp extends BaseResp {
  list: Factory[];
  totalCount: number;
}

export interface FactoryResp extends BaseResp {
  info: Equipment;
}

export const getCustomerList = (
  req: CustomerSearchRequest,
): Promise<CustomerListResp> => client.get('v1/customer/list', { params: req });
export const getCustomer = (customerId: number): Promise<CustomerResp> =>
  client.get(`v1/customer/${customerId}`);
export const getCustomerChangeHistory = (req: LogSearchRequest): Promise<CustomerChangeHistoryResp> =>
  client.get(`v1/customer/${req.id}/log`, { params: req });
const registerCustomer = (formData: CustomerFormData): Promise<BaseResp> =>
  client.post('v1/customer/regist', formData);
const updateCustomer = (customer: CustomerFormData): Promise<BaseResp> =>
  client.post(`v1/customer/${customer.customerId}`, { ...customer });

export const useRegisterCustomerMutation = () =>
  useMutation({ mutationFn: registerCustomer });
export const useUpdateCustomerMutation = () =>
  useMutation({ mutationFn: updateCustomer });

export const downloadCustomerListExcel = (req: CustomerSearchRequest) =>
  downloadExcel(req, 'v1/customer/excel', '고객사목록');

export const getFactoryList = (customerId: number): Promise<FactoryListResp> => client.get(`v1/customer/${customerId}/factory`);
export const getFactory = (factoryId: number): Promise<FactoryResp> => client.get(`v1/customer/factory/${factoryId}`)

const registerFactory = (factory: FactoryFormData): Promise<BaseResp> =>
  client.post(`v1/customer/${factory.customerId}/factory`, factory);
const updateFactory = (factory: FactoryFormData): Promise<BaseResp> =>
  client.post(`v1/customer/factory/${factory.factoryId}`, factory);
const deleteFactory = (id: number): Promise<BaseResp> =>
  client.delete(`v1/customer/factory/${id}`);

const registerFactoryImage = (factory: FactoryImageFormData): Promise<BaseResp> =>
  axios.postForm(`customer/factory/${factory.factoryId}/thumb`, { ...factory, image: factory.image[0] }, { baseURL: `${process.env.REACT_APP_BASE_URL}/api`, withCredentials: true })



export const useRegisterFactoryMutation = () =>
  useMutation({ mutationFn: registerFactory });
export const useUpdateFactoryMutation = () =>
  useMutation({ mutationFn: updateFactory });
export const useDeleteFactoryMutation = () =>
  useMutation({ mutationFn: deleteFactory });
export const useRegisterFactoryImageMutation = () =>
  useMutation({ mutationFn: registerFactoryImage });



