import { ChangeEvent } from 'react';

type PageCountBoxProps = {
  pageCount: number;
  onChangePageCount: (event: ChangeEvent<HTMLSelectElement>) => void;
};

export default function PageCountBox({
  pageCount,
  onChangePageCount,
}: PageCountBoxProps) {
  return (
    <select
      className={'input-box-type-01'}
      onChange={onChangePageCount}
      value={pageCount}
    >
      {[10, 20, 50, 100].map((count) => (
        <option key={count} value={count}>
          {count}
        </option>
      ))}
    </select>
  );
}
