import Image from 'components/widget/Image';
import { Order } from 'model/Order';
import { convertOrderState } from 'util/stringUtil';

type LotoOrderInfoProps = {
  order: Order | undefined;
};

export default function LotoOrderInfo({ order }: LotoOrderInfoProps) {
  return (
    <>
      <section>
        <div className={'sub-title'}>
          <h3>{'작업정보'}</h3>
        </div>
        <div>
          <table className={'tbl-type-05 input-design input-small w100'}>
            <caption>{'작업허가서'}</caption>
            <colgroup>
              <col width={150} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <p>{'작업허가서'}</p>
                </th>
                <td>
                  <p>{order?.name}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>{'작업일자'}</p>
                </th>
                <td>
                  <p>{order?.workDate}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>{'설비'}</p>
                </th>
                <td>
                  <p>{order?.equipName}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>{'작업내용'}</p>
                </th>
                <td>
                  <p>{order?.detail}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>{'작업상태'}</p>
                </th>
                <td>
                  <p>{convertOrderState(order?.state ?? '')}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section className={'mgt-2rem'}>
        <div className={'sub-title'}>
          <h3>{'승인정보'}</h3>
        </div>
        <table className={'tbl-type-05 input-design input-small w100'}>
          <tbody>
            <tr>
              <th>
                <p>{'부착확인'}</p>
              </th>
              <td>
                <p>{order?.lockConfirmDate}</p>
                <p>{order?.lockUserName}</p>
              </td>
              <td className={'sign-wrap'}>
                <p>{'(인)'}</p>
                <Image src={order?.lockUserSign} alt={'부착확인 서명'} />
              </td>
              <th>
                <p>{'제거승인'}</p>
              </th>
              <td>
                <p>{order?.approveDate}</p>
                <p>{order?.approveUserName}</p>
              </td>
              <td className={'sign-wrap'}>
                <p>{'(인)'}</p>
                <Image src={order?.approveUserSign} alt={'제거승인 서명'} />
              </td>
              <th>
                <p>{'제거확인'}</p>
              </th>
              <td>
                <p>{order?.unlockConfirmDate}</p>
                <p>{order?.unlockUserName}</p>
              </td>
              <td className={'sign-wrap'}>
                <p>{'(인)'}</p>
                <Image src={order?.unlockUserSign} alt={'부착확인 서명'} />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  );
}
