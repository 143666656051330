import { useQuery } from '@tanstack/react-query';
import { FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactModal from 'react-modal';

import {
  getNotice,
  NoticeFormData,
  useRemoveNoticeImageMutation,
} from 'api/noticeApi';
import { noticeKeys } from 'api/queryKeys/queryKeys';
import TextEditor from 'components/widget/TextEditor';
import { YN_TYPE } from 'types/enumType';
import { imgSrcRegex } from 'util/regexUitil';
import { customStyles } from 'util/styleUtil';

type NoticeModalProps = {
  noticeId: number;
  isOpen: boolean;
  onSubmit: (formData: NoticeFormData) => void;
  onClose: () => void;
};
export default function NoticeModal({
  noticeId,
  isOpen,
  onSubmit,
  onClose,
}: NoticeModalProps) {
  const methodTypeName = noticeId > 0 ? '수정' : '등록';

  const [uploadImageList, setUploadImageList] = useState<string[]>([]);
  const getImgUrlList = (content: string): string[] => {
    const imgList = Array.from(content.matchAll(imgSrcRegex));
    return imgList.map((img) => img[1]);
  };

  const { data, refetch } = useQuery({
    queryKey: noticeKeys.detail(noticeId),
    queryFn: () => getNotice(noticeId),
    enabled: false,
  });

  const {
    register,
    getValues,
    setValue,
    setFocus,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<NoticeFormData>();
  const watchBody = watch('body');

  useEffect(() => {
    if (noticeId < 0) {
      reset({
        id: -1,
        activate: YN_TYPE.Y,
      });
      setUploadImageList([]);

      setTimeout(() => {
        setFocus('title');
      }, 100);
    } else {
      const refetchDetailQuery = async () => {
        const { data: notice } = await refetch();
        if (notice?.code === 200) {
          const resp = notice?.info;
          reset({
            ...resp,
            id: noticeId,
          });
          setUploadImageList(getImgUrlList(resp.body ?? ''));
        } else {
          alert(notice?.message);
        }
      };
      refetchDetailQuery();
    }
  }, [isOpen]);

  const onChangeBody = (text: string) => {
    setValue('body', text);
  };
  const addImage = (img: string) => {
    setUploadImageList((prev) => [...prev, img]);
  };

  const removeNoticeImageMutation = useRemoveNoticeImageMutation();
  const interceptorBody = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const bodyUrlList = getImgUrlList(getValues('body') ?? '');
    const removeUrlList = uploadImageList
      .filter((upload) => !bodyUrlList.some((body) => upload === body))
      .map((url) => url.substring(url.lastIndexOf('/NOTICE')));

    if (removeUrlList.length > 0) {
      removeNoticeImageMutation.mutateAsync(removeUrlList, {
        onSuccess: (resp) => {
          if (resp.code !== 200) {
            console.log(resp.message);
          }
        },
      });
    }

    handleSubmit(onSubmit)(e);
  };

  return (
    <ReactModal isOpen={isOpen} style={customStyles}>
      <section>
        <div className={'pop-wrap'}>
          <form onSubmit={interceptorBody}>
            <div className={'pop-header'}>
              <h2>
                <span>
                  {'공지사항'} {methodTypeName}
                </span>
              </h2>
            </div>
            <div className={'pop-body'}>
              <table className={'tbl-type-05 input-design input-small w100'}>
                <caption>{'공지사항 상세'}</caption>
                <colgroup>
                  <col width={'25%'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <p>{'제목'}</p>
                    </th>
                    <td>
                      <div className={'input-box-group'}>
                        <input
                          type={'text'}
                          className={'input-box-type-01'}
                          {...register('title')}
                          placeholder={''}
                          maxLength={50}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p>{'사용여부'}</p>
                    </th>
                    <td aria-label={'td'}>
                      <select
                        className={'input-box-type-01'}
                        {...register('activate')}
                      >
                        <option value={YN_TYPE.Y}>{'사용'}</option>
                        <option value={YN_TYPE.N}>{'미사용'}</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p>{'내용'}</p>
                    </th>
                    <td>
                      <div className={'input-box-group max-h-50rem'}>
                        {/* text editor 영역 */}
                        <TextEditor
                          value={data?.info.body ?? ''}
                          onChange={onChangeBody}
                          onUploadImage={addImage}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={'pop-bottom input-large'}>
              <button type={'submit'} className={'btn-type-01'}>
                {noticeId > 0 ? '저장' : '등록'}
              </button>
              <button
                type={'button'}
                className={'btn-type-02'}
                onClick={onClose}
              >
                {'닫기'}
              </button>
            </div>
          </form>
        </div>
      </section>
    </ReactModal>
  );
}
