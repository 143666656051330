import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Account, useSignInMutation } from 'api/sessionApi';
import { useRecoilState } from 'recoil';
import { userInfoState } from 'recoil/user/atom';
import { encryptString } from 'util/encryptUtil';

export default function SignIn() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<Account>();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  useEffect(() => {
    setFocus('account');
  }, []);

  const { mutate } = useSignInMutation();
  const onSubmit: SubmitHandler<Account> = (data) => {
    mutate(data, {
      onSuccess(response) {
        if (response.code === 200) {
          const {
            customerId,
            customerName,
            factoryName,
            factoryId,
            areaUseYn,
            name,
            latestDt,
            role,
            userId,
          } = response.info;
          setUserInfo({
            customerId: (customerId ?? '').toString(),
            customerName: customerName ?? '',
            factoryId: (factoryId ?? '').toString(),
            factoryName,
            areaUseYn,
            role: encryptString(role.toString()),
            name,
            latestDt,
            isAuthenticated: true,
            userId: encryptString(userId.toString()),
          });
          navigate('/home');
        } else {
          alert(response.message);
        }
      },
    });
  };

  return (
    <div className={'login-box-wrap'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'input-box2 mt-20'}>
          <input
            type={'text'}
            {...register('account', { required: '아이디를 입력해주세요' })}
            placeholder={'아이디'}
          />
          <span className={'id'} />
          <i />
        </div>
        <div className={'input-box2'}>
          <input
            type={'password'}
            {...register('passwd', { required: '비밀번호를 입력해주세요' })}
            placeholder={'비밀번호'}
          />
          <span className={'pw'} />
          <i />
        </div>
        <button type={'submit'} className={'btn-type-login'}>
          {'Login'}
        </button>
      </form>
    </div>
  );
}
