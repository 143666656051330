/* eslint-disable no-param-reassign */
import { useQuery } from '@tanstack/react-query';
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getDeviceInfoListByArea } from 'api/homeApi';
import { dashboardKeys } from 'api/queryKeys/queryKeys';
import DashboardInfo from 'model/DashboardInfo';
import { getDeviceImgOption, getMinRatio } from 'util/canvasUtil';
import { getLockerImgByConclusion } from 'util/commonUtil';

import LotoOrderInfoModal from 'components/order/LotoOrderInfoModal';
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/user/atom';
import { ROLE_TYPE } from 'types/enumType';
import { decryptString } from 'util/encryptUtil';
import DeviceInfoModal from './DeviceInfoModal';

export default function DeviceInfoPopup() {
  const { role } = useRecoilValue(userInfoState);
  const { id } = useParams();

  const canvasRef = useRef<HTMLDivElement>(null);
  const [areaImage, setAreaImage] = useState<string>('');
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>(
    { width: 0, height: 0 },
  );
  const [ratio, setRatio] = useState<number>(1);
  const [centerRect, setCenterRect] = useState<{ left: number; top: number }>({
    left: 0,
    top: 0,
  });
  const [imgCanvas, setImgCanvas] = useState<fabric.Image>();
  const [deviceInfo, setDeviceInfo] = useState<DashboardInfo | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [posX, setPosX] = useState<number>(0);
  const [posY, setPosY] = useState<number>(0);

  const [activeOrderId, setActiveOrderId] = useState<number>(-1);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);

  const { editor, onReady } = useFabricJSEditor();

  const { data: deviceList, refetch: refetchDevice } = useQuery({
    queryKey: dashboardKeys.deviceList(+id!),
    queryFn: () => getDeviceInfoListByArea(+id!),
  });

  // useEffect 목록 detect -> create Rect
  useEffect(() => {
    if (!deviceList) {
      return;
    }

    setAreaImage(deviceList.image ?? '');
  }, [deviceList]);

  const getCanvasSize = (): { width: number; height: number } | null => {
    if (!canvasRef.current || !canvasRef.current) {
      return null;
    }

    return {
      width: canvasRef.current.clientWidth,
      height: canvasRef.current.clientHeight,
    };
  };

  const resizeCanvas = () => {
    const section = getCanvasSize();
    if (
      !section ||
      !imageSize.width ||
      !imageSize.height ||
      !editor?.canvas ||
      !editor.canvas.backgroundImage
    ) {
      return;
    }

    const minRatio = getMinRatio(section, imageSize);
    setRatio(minRatio);

    editor?.canvas.setHeight(section.height);
    editor?.canvas.setWidth(section.width);

    const center = {
      left: (section.width - imageSize.width * minRatio) / 2,
      top: (section.height - imageSize.height * minRatio) / 2,
    };
    setCenterRect(center);
    imgCanvas?.setOptions(center);

    editor?.canvas.setBackgroundImage(
      imgCanvas!,
      editor?.canvas.renderAll.bind(editor?.canvas),
      {
        scaleX: minRatio,
        scaleY: minRatio,
      },
    );

    if (editor?.canvas && editor.canvas.getContext()) {
      editor?.canvas.remove(...editor.canvas.getObjects());
    }

    if (deviceList && deviceList?.equips?.length > 0) {
      deviceList.equips
        .filter((device) => device.left > 0 && device.top > 0)
        .forEach((device) => {
          const { lockLocked, lockUnlocked } = device;
          const deviceImage = getLockerImgByConclusion(
            lockLocked,
            lockUnlocked,
          );
          fabric.Image.fromURL(deviceImage, (oImg) => {
            editor?.canvas.add(
              getDeviceImgOption(oImg, device, minRatio, center),
            );

            editor?.canvas.add(oImg);
          });
        });
    }
  };

  const debounceResize = _.debounce(resizeCanvas, 300);
  useEffect(() => {
    const handleWindowResize = () => {
      if (!editor?.canvas) {
        return;
      }

      debounceResize();
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [editor]);

  const handleOpenModal = (event: fabric.IEvent<MouseEvent>) => {
    const selectObj = event.target;
    if (!selectObj) {
      return;
    }
    const [type, deviceId] = selectObj.name!.split('/');
    setDeviceInfo(
      deviceList!.equips.find((item) => item.id === Number(deviceId))!,
    );
    const { clientWidth, clientHeight } = canvasRef.current ?? {
      clientWidth: 0,
      clientHeight: 0,
    };
    setPosX(
      event.e.clientX > clientWidth - 150 ? clientWidth - 150 : event.e.clientX,
    );
    setPosY(
      event.e.clientY > clientHeight - 220
        ? clientHeight - 220
        : event.e.clientY,
    );
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setDeviceInfo(null);
    setIsOpen(false);
  };

  const handleCanvasReady = (canvas: fabric.Canvas) => {
    fabric.Image.fromURL(areaImage ?? '', (img) => {
      setImgCanvas(img);

      if (!img.width || !img.height) {
        return;
      }

      const section = { width: 1400, height: 1000 };
      const { width: iw, height: ih } = img;
      const minRatio = getMinRatio(section, { width: iw, height: ih });
      setRatio(minRatio);
      canvas.setHeight(section.height);
      canvas.setWidth(section.width);

      const center = {
        left: (section.width - iw * minRatio) / 2,
        top: (section.height - ih * minRatio) / 2,
      };
      setCenterRect(center);
      img.setOptions(center);

      setImageSize({ width: iw, height: ih });

      canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
        scaleX: minRatio,
        scaleY: minRatio,
      });

      if (deviceList && deviceList?.equips?.length > 0) {
        deviceList.equips
          .filter((device) => device.left > 0 && device.top > 0)
          .forEach((device) => {
            const { lockLocked, lockUnlocked } = device;
            const deviceImage = getLockerImgByConclusion(
              lockLocked,
              lockUnlocked,
            );
            fabric.Image.fromURL(deviceImage, (oImg) => {
              canvas.add(getDeviceImgOption(oImg, device, minRatio, center));
              // canvas.add(oImg);
            });
          });
      }

      canvas.selection = false;
      canvas.renderAll();
      onReady(canvas);
    });
  };

  useEffect(() => {
    if (!editor?.canvas) {
      return;
    }

    const enablePanning = () => {
      let isDragging = false;
      let lastX: number = 0;
      let lastY: number = 0;

      editor?.canvas.on('mouse:down', (event) => {
        const pointer = editor?.canvas.getPointer(event.e);
        lastX = pointer.x;
        lastY = pointer.y;
        isDragging = true;
      });

      editor.canvas.on('mouse:move', (event) => {
        if (!isDragging) return;

        const pointer = editor.canvas.getPointer(event.e);
        lastX = pointer.x;
        lastY = pointer.y;

        const delta = new fabric.Point(event.e.movementX, event.e.movementY);
        editor.canvas?.relativePan(delta);
      });

      editor?.canvas.on('mouse:up', (event) => {
        isDragging = false;

        const pointer = editor?.canvas.getPointer(event.e);
        const diffX = Math.abs(pointer.x - lastX);
        const diffY = Math.abs(pointer.y - lastY);

        if (diffX === 0 && diffY === 0) {
          handleOpenModal(event);
        }
      });

      editor?.canvas.on('mouse:wheel', (opt) => {
        const delta = opt.e.deltaY;
        let zoom = editor.canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom <= 1) zoom = 1;
        editor.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();

        const vpt = editor.canvas.viewportTransform;
        const canvasW = editor.canvas.getWidth();
        const canvasH = editor.canvas.getHeight();
        if (vpt && zoom === 1) {
          if (zoom < 400 / canvasW) {
            vpt[4] = 200 - (canvasW * zoom) / 2;
            vpt[5] = 200 - (canvasW * zoom) / 2;
          } else {
            if (vpt[4] >= 0) {
              vpt[4] = 0;
            } else if (vpt[4] < editor.canvas.getWidth() - canvasW * zoom) {
              vpt[4] = editor.canvas.getWidth() - canvasW * zoom;
            }
            if (vpt[5] >= 0) {
              vpt[5] = 0;
            } else if (vpt[5] < editor.canvas.getHeight() - canvasH * zoom) {
              vpt[5] = editor.canvas.getHeight() - canvasH * zoom;
            }
          }
        }
      });
    };

    enablePanning();
  }, [editor?.canvas]);

  const refetchDeviceObj = () => {
    if (editor?.canvas && editor.canvas.getContext()) {
      editor?.canvas.remove(...editor.canvas.getObjects());
    }

    refetchDevice();
  };

  const onOpenOrderModal = () => {
    setActiveOrderId(deviceInfo?.orderIds[0] ?? -1);
    setIsOrderModalOpen(true);
  };

  const onCloseOrderModal = () => {
    setActiveOrderId(-1);
    setIsOrderModalOpen(false);
  };

  return (
    <>
      <div className={'devicePopup-wrap'}>
        <div className={'canvas-container'} ref={canvasRef}>
          {areaImage && (
            <FabricJSCanvas
              className={'sample-canvas'}
              onReady={handleCanvasReady}
            />
          )}
        </div>
        <DeviceInfoModal
          info={deviceInfo}
          isOpen={isOpen}
          posX={posX}
          posY={posY}
          onOpenOrderInfo={onOpenOrderModal}
          onClose={handleCloseModal}
        />
      </div>
      {isOrderModalOpen && (
        <LotoOrderInfoModal
          orderId={activeOrderId}
          isOpen={isOrderModalOpen}
          isEditable={decryptString(role) !== ROLE_TYPE.USER}
          onRefreshList={refetchDeviceObj}
          onClose={onCloseOrderModal}
        />
      )}
    </>
  );
}
