import { LOCKER_MODE, ROLE_TYPE, YN_TYPE } from "types/enumType";


export const convertRoleToString = (role: ROLE_TYPE | string): string => {
  switch (role) {
    case ROLE_TYPE.SUPER_ADMIN:
      return '슈퍼관리자';
    case ROLE_TYPE.SUPER_MANAGER:
      return '슈퍼매니저';
    case ROLE_TYPE.ADMIN:
      return '관리자';
    case ROLE_TYPE.MANAGER:
      return '매니저';
    case ROLE_TYPE.USER:
      return '사용자';
    default:
      return '사용자';
  }
}

export const convertModeToName = (mode: LOCKER_MODE): string => {
  switch (mode) {
    case LOCKER_MODE.SERVER:
      return '서버모드';
    case LOCKER_MODE.STAND_ALONE:
      return '독립모드';
    default:
      return '-';
  }
}

export const convertOrderState = (state: string): string => {
  switch (state) {
    case 'ALL':
      return 'ALL';
    case 'LOCKING':
      return '부착 중';
    case 'LOCKED':
      return '부착 확정';
    case 'APPROVED':
      return '제거 승인';
    case 'COMPLETE':
      return '제거 확정';
    default:
      return '-';
  }
}

export const convertYnTypeToString = (type: YN_TYPE | string) => type === YN_TYPE.Y ? '사용' : '미사용';

export const formatPhoneNumber = (value: string) => {
  if (!value) {
    return '';
  }

  const cleaned = value.replace(/\D/g, ''); // 숫자가 아닌 문자는 제거
  const match = cleaned.match(/(\d{3})(\d{4})(\d{4})/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  } if (cleaned.length > 7) {
    return cleaned.replace(/(\d{3})(\d{4})(\d{0,4})/, '$1-$2-$3');
  } if (cleaned.length > 3) {
    return cleaned.replace(/(\d{3})(\d{0,4})/, '$1-$2');
  }

  return cleaned;
};