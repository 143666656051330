import DashboardInfo from "model/DashboardInfo";

import { BaseResp } from "./BaseResp";
import { client } from "./client";

export interface DashboardMapResp extends BaseResp {
  areas: DashboardInfo[];
  equips: DashboardInfo[];
  homeImage: string;
}

export interface DashboardStatusResp extends BaseResp {
  lockerLock: number;
  lockerTotal: number;
  lockerUnlock: number;
  message: string;
  orderApprove: number;
  orderLock: number;
  orderLocked: number;
  orderTotal: number;
  userLock: number;
  userTotal: number;
  userUnlock: number;
}

export interface DashboardDeviceList {
  equips: DashboardInfo[];
  image: string;
}

export const getFactoryImg = (factoryId: number): Promise<{ path: string }> => client.get(`v1/home/factory/${factoryId}`);
export const getDashboardMap = (factoryId: number): Promise<DashboardMapResp> => client.get('v1/home/dashboard', { params: { factoryId } });
export const getDashboardStatus = (factoryId: number): Promise<DashboardStatusResp> => client.get('v1/home/statistics', { params: { factoryId } });
export const getDeviceInfoListByArea = (areaId: number): Promise<DashboardDeviceList> => client.get(`/v1/home/dashboard/${areaId}`);