import ReactModal from 'react-modal';

import Image from 'components/widget/Image';
import { customStyles } from 'util/styleUtil';

type DeviceQrModalProps = {
  src: string;
  isOpen: boolean;
  onClose: () => void;
};

export default function DeviceQrModal({
  src,
  isOpen,
  onClose,
}: DeviceQrModalProps) {
  return (
    <ReactModal isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
      <section>
        <div className={'pop-wrap middle-pop'}>
          <div className={'pop-header'}>
            <h2>
              <span>{'설비 QR코드 이미지'}</span>
            </h2>
          </div>
          <div className={'pop-body'}>
            <Image src={src} width={600} height={600} alt={'device qr img'} />
          </div>
          <div className={'pop-bottom input-large'}>
            <button type={'button'} className={'btn-type-02'} onClick={onClose}>
              {'닫기'}
            </button>
          </div>
        </div>
      </section>
    </ReactModal>
  );
}
