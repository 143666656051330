import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getDashboardStatus } from 'api/homeApi';
import { dashboardKeys } from 'api/queryKeys/queryKeys';
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/user/atom';

import dashboardItemList from './dashboardItemList';
import SidebarNavList from './SidebarNavList';
import StatusBoard from './StatusBoard';

export default function Sidebar() {
  const location = useLocation();
  const { factoryId } = useRecoilValue(userInfoState);

  const { data: dashboardData, refetch: refetchDashboard } = useQuery({
    queryKey: dashboardKeys.status(Number(factoryId)),
    queryFn: () => getDashboardStatus(Number(factoryId)),
    enabled: false,
  });

  useEffect(() => {
    if (location.pathname.includes('home') && !!factoryId) {
      refetchDashboard();
    }
  }, [location.pathname, factoryId]);

  return (
    <nav className={'left-menu'}>
      <SidebarNavList items={dashboardItemList} />
      {location.pathname.includes('home') && dashboardData && (
        <StatusBoard statusInfo={dashboardData ?? null} />
      )}
    </nav>
  );
}
