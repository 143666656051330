import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/user/atom';
import { ROLE_TYPE, YN_TYPE } from 'types/enumType';
import { SidebarItemType } from 'types/sidebar';
import { decryptString } from 'util/encryptUtil';

import SidebarNavDepthItem from './SidebarNavDepthItem';
import SidebarNavItem from './SidebarNavItem';

type SidebarNavListProps = {
  items: SidebarItemType[];
};

export default function SidebarNavList({ items }: SidebarNavListProps) {
  const userInfo = useRecoilValue(userInfoState);
  return (
    <ul>
      {items &&
        items.map((item) => {
          if (
            item.href === '/users/user' &&
            (decryptString(userInfo.role) === ROLE_TYPE.MANAGER ||
              decryptString(userInfo.role) === ROLE_TYPE.USER)
          ) {
            return null;
          }

          if (!item.children || item.children?.length === 0) {
            return (
              <SidebarNavItem
                key={item.href}
                item={item}
                className={'depth-01'}
              />
            );
          }
          return (
            <SidebarNavDepthItem
              key={item.href}
              item={item}
              userRole={decryptString(userInfo.role)}
              areaUseYn={userInfo.areaUseYn ?? YN_TYPE.N}
              className={'depth-01'}
            />
          );
        })}
    </ul>
  );
}
