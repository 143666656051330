import { FieldErrors } from 'react-hook-form';

type ErrorMessageProps = {
  errors: FieldErrors;
};

export default function ErrorMessage({ errors }: ErrorMessageProps) {
  if (!errors) {
    return null;
  }

  const getErrorMessage = () =>
    errors[Object.keys(errors)[0]]?.message?.toString();

  return <p className={'cred error'}>{getErrorMessage()}</p>;
}
