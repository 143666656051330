import { ReactElement, ReactNode, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/user/atom';
import { ROLE_TYPE } from 'types/enumType';
import { decryptString } from 'util/encryptUtil';

interface AuthGuardProps {
  children: ReactNode;
}

const getNavigateAfterCheckLogin = (
  url: string,
  isAuthenticated: boolean,
): ReactElement | null => {
  if (isAuthenticated) {
    if (url === '/sign-in' || url === '/') {
      return <Navigate to={'/home'} replace />;
    }
  } else {
    if (!(url === '/sign-in')) {
      return <Navigate to={'/sign-in'} replace />;
    }

    return null;
  }

  return null;
};

const getNavigateAfterCheckAuthority = (
  url: string,
  role: string,
): ReactElement | null => {
  if (
    url.startsWith('/users/user') &&
    (role === ROLE_TYPE.MANAGER || role === ROLE_TYPE.USER)
  ) {
    alert('접근 권한이 없습니다.');
    return <Navigate to={'/home'} replace />;
  }

  if (
    url.startsWith('/users/customer') &&
    !(role === ROLE_TYPE.SUPER_MANAGER || role === ROLE_TYPE.SUPER_ADMIN)
  ) {
    alert('접근 권한이 없습니다.');
    return <Navigate to={'/home'} replace />;
  }

  if (url.startsWith('/users/admin') && role !== ROLE_TYPE.SUPER_ADMIN) {
    alert('접근 권한이 없습니다.');
    return <Navigate to={'/home'} replace />;
  }

  return null;
};
const AuthGuard = ({ children }: AuthGuardProps) => {
  const { role, isAuthenticated } = useRecoilValue(userInfoState);
  const location = useLocation();
  const userRole = decryptString(role);
  const url = location.pathname;

  if (!userRole) {
    sessionStorage.clear();
    if (url === '/sign-in') {
      return <Suspense>{children}</Suspense>;
    }

    alert('권한에 문제가 발생했습니다.\n다시 로그인 해주세요');
    return <Navigate to={'/sign-in'} replace />;
  }

  const navigateElement =
    getNavigateAfterCheckLogin(url, isAuthenticated) ||
    getNavigateAfterCheckAuthority(url, userRole);
  if (navigateElement) {
    return navigateElement;
  }

  return <Suspense>{children}</Suspense>;
};

export default AuthGuard;
