import { BaseResp } from "./BaseResp";
import { client } from "./client";
import { LockerSearchRequest } from "./request/LockerSearchRequest";
import { LogSearchRequest } from "./request/LogSearchRequest";
import { UserSearchRequest } from "./request/UserSearchRequest";

export const downloadExcel = (req: LogSearchRequest | LockerSearchRequest | UserSearchRequest, path: string, fileName: string) =>
  client
    .get<BaseResp, Blob>(path, { responseType: 'blob', params: { ...req } })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}_${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
