export const enum ROLE_TYPE {
  SUPER_ADMIN = '0',
  SUPER_MANAGER = '1',
  ADMIN = '2',
  MANAGER = '3',
  USER = '4',
}

export const enum PAGE_TYPE {
  ALL_PREV,
  PREV,
  NEXT,
  ALL_NEXT,
}

export const enum YN_TYPE {
  Y = 'Y',
  N = 'N',
}

export const enum LOG_TABLE_TYPE {
  LOG,
  LOCKER,
  USER
}

export const enum LOCKER_STATE {
  LOCKED = 'LOCKED',
  UNLOCK = 'UNLOCK',
  CONNECT = 'CONNECT',
}

export const enum LOCKER_TYPE {
  LOCKER = 'LOCKER',
  DOOR = 'DOOR',
  BOOKSHELF = 'BOOKSHELF'
}

export const enum LOG_TAB_TYPE {
  LOCK_LOG = 0,
  CHANGE_LOG = 1,
}

export const enum LOCKER_MODE {
  SERVER = 1,
  STAND_ALONE = 2,
}

export const enum DEVICE_TYPE {
  FACTORY = '공장',
  AREA = 'Area',
  DEVICE = '설비'
}

export const enum ACTION_TYPE {
  REGISTER,
  UPDATE,
}

export const enum ORDER_STATE {
  ALL = 'ALL',
  LOCKING = 'LOCKING',
  LOCKED = 'LOCKED',
  APPROVED = 'APPROVED',
  COMPLETE = 'COMPLETE'
}

export const enum ORDER_EVENT_TYPE {
  ATTACHMENT_CONFIRM,
  ATTACHMENT_CONFIRM_CANCEL,
  DELETE_APPROVE,
  DELETE_APPROVE_CANCEL,
  DELETE_CONFIRM,
  PRINT
}

export const enum INQUIRY_EDIT_TYPE {
  INQUIRY_REGISTER,
  INQUIRY_UPDATE,
  ANSWER_REGISTER,
  ANSWER_UPDATE
}