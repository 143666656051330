import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { Notice } from 'model/Notice';
import { YN_TYPE } from 'types/enumType';

import { BaseResp } from './BaseResp';
import { client } from './client';
import { NoticeSearchRequest } from './request/NoticeSearchRequest';

export interface NoticeListResp extends BaseResp {
  totalCount: number;
  list: Notice[];
}

export interface NoticeResp extends BaseResp {
  info: Notice;
}

export interface NoticeFormData {
  id: number;
  title: string;
  body: string;
  activate: YN_TYPE;
}

export interface ImageResp extends BaseResp {
  pathUrl: string;
}

export const getNoticeList = (
  req: NoticeSearchRequest,
): Promise<NoticeListResp> => client.get('v1/notice', { params: req });

export const getNotice = (noticeId: number): Promise<NoticeResp> =>
  client.get(`v1/notice/${noticeId}`);

const registerNotice = (req: NoticeFormData): Promise<BaseResp> => client.post('v1/notice', { ...req });
const updateNotice = (req: NoticeFormData): Promise<BaseResp> => client.post(`v1/notice/${req.id}`, { ...req });
// const deleteNotice = (noticeId: number): Promise<BaseResp> => client.delete(`v1/notice/${noticeId}`);

export const useRegisterNoticeMutation = () => useMutation({ mutationFn: registerNotice });
export const useUpdateNoticeMutation = () => useMutation({ mutationFn: updateNotice });

export const uploadNoticeImage = (image: File) =>
  axios.postForm(
    `/v1/notice/file`,
    { thumb: image },
    { baseURL: `${process.env.REACT_APP_BASE_URL}/api`, withCredentials: true }
  );
const removeNoticeImage = (pathList: string[]): Promise<BaseResp> => client.post('v1/notice/file/del', { paths: pathList.toString() });
export const useRemoveNoticeImageMutation = () => useMutation({ mutationFn: removeNoticeImage });
