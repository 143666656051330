import { useEffect, useState } from 'react';

type PaginationProps = {
  totalCount: number;
  page: number;
  count: number;
  onPageClick: (page: number) => void;
};
type PageInfo = {
  pageNumber: number;
  name: string;
};

export default function Pagination({
  totalCount,
  page,
  count,
  onPageClick,
}: PaginationProps) {
  const [pageList, setPageList] = useState<PageInfo[]>([]);

  const generatePagination = (
    curPage: number,
    totalCnt: number,
    cnt: number,
  ) => {
    const pages: PageInfo[] = [];
    const pageCntShow = 10;

    const pageCntTotal = Math.ceil(totalCnt / cnt);
    const startPage = Math.floor((curPage - 1) / pageCntShow) * pageCntShow + 1;
    const endPage = Math.min(pageCntTotal, startPage + pageCntShow - 1);

    if (startPage > pageCntShow) {
      pages.push({ pageNumber: 1, name: 'a_left amore' });
      pages.push({ pageNumber: startPage - pageCntShow, name: 'a_left' });
    }

    for (let i = startPage; i <= endPage; i += 1) {
      pages.push({ pageNumber: i, name: i.toString() });
    }

    if (endPage < pageCntTotal) {
      pages.push({ pageNumber: startPage + pageCntShow, name: 'a_right' });
      pages.push({ pageNumber: pageCntTotal, name: 'a_right amore' });
    }

    setPageList(pages);
  };

  useEffect(() => {
    generatePagination(page, totalCount, count);
  }, [page, totalCount, count]);

  const iClass = (name: string): string | null => {
    switch (name) {
      case 'a_left amore':
        return 'a_left amore';
      case 'a_left':
        return 'a_left';
      case 'a_right':
        return 'a_right';
      case 'a_right amore':
        return 'a_right amore';
      default:
        return null;
    }
  };

  const pageListComponent = pageList.map((item) => (
    <li
      key={item.pageNumber}
      className={`${page === item.pageNumber ? 'active' : ''}`}
    >
      {!iClass(item.name) && (
        <button type={'button'} onClick={() => onPageClick(item.pageNumber)}>
          {item.name}
        </button>
      )}
      {iClass(item.name) && (
        <button
          type={'button'}
          onClick={() => onPageClick(item.pageNumber)}
          className={item.name}
        >
          {''}
        </button>
      )}
    </li>
  ));

  return (
    <div className={'paging'}>
      <ul className={'pagination'}>{pageListComponent}</ul>
    </div>
  );
}
