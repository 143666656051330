import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import ReactModal from 'react-modal';
import { RouterProvider } from 'react-router-dom';

import { RecoilRoot } from 'recoil';
import routes from 'routes/routes';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, staleTime: 0, gcTime: 1000 },
  },
});
ReactModal.setAppElement('#root');

root.render(
  // <React.StrictMode></React.StrictMode>
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={'..loading'}>
        <RouterProvider router={routes} />
        <div
          style={{
            fontSize: '18px',
          }}
        >
          {/* <ReactQueryDevtools initialIsOpen={false} position={'bottom'} /> */}
        </div>
      </Suspense>
    </QueryClientProvider>
  </RecoilRoot>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
