import { useRef } from 'react';
import ReactModal from 'react-modal';
import SignaturePad from 'react-signature-canvas';

type SignModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (signImgSrc: string) => void;
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 0,
  },
};
export default function SignModal({
  isOpen,
  onClose,
  onSubmit,
}: SignModalProps) {
  const sigCanvas = useRef<SignaturePad>(null);
  const clearSign = () => {
    sigCanvas.current?.clear();
  };

  const onSaveImg = () => {
    const url =
      sigCanvas.current?.getTrimmedCanvas().toDataURL('image/png') ?? '';
    if (!url) {
      alert('싸인이 등록되지 않았습니다.');
      return;
    }
    onSubmit(url);
  };

  return (
    <ReactModal isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
      <div id={'popup-wrap'}>
        <div className={'pop-wrap small-pop'}>
          <div className={'pop-header'}>
            <h2>
              <span>{'서명 등록'}</span>
            </h2>
          </div>
          <div className={'pop-body'}>
            <div className={'cont'}>
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{ className: 'sigCanvas' }}
              />
            </div>
          </div>
          <div className={'pop-bottom input-large'}>
            <button
              type={'button'}
              className={'btn-type-01'}
              onClick={onSaveImg}
            >
              {'저장'}
            </button>
            <button
              type={'button'}
              className={'btn-type-05'}
              onClick={clearSign}
            >
              {'초기화'}
            </button>
            <button type={'button'} className={'btn-type-02'} onClick={onClose}>
              {'취소'}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
