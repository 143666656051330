import { useEffect, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';

import { uploadNoticeImage } from '../../api/noticeApi';

import 'react-quill/dist/quill.snow.css';

// const Size = Quill.import('formats/size');
// Size.whitelist = ['8', '9', '10', '11', '12', '14', '18', '24', '36'];
// Quill.register(Size, true);

type TextEditorProps = {
  value: string;
  isEnable?: boolean;
  onChange?: (text: string) => void;
  onUploadImage?: (img: string) => void;
};

export default function TextEditor({
  value,
  isEnable = true,
  onChange = () => {},
  onUploadImage = () => {},
}: TextEditorProps) {
  // const [value, setValue] = useState('');

  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    if (!isEnable) {
      // quillRef.current?.editor?.disable();
      quillRef.current?.editor?.enable(false);
    }
  }, [isEnable]);

  useEffect(() => {
    if (value) {
      quillRef.current?.editor?.clipboard.dangerouslyPasteHTML(value);
    }
  }, [value]);

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.addEventListener('change', async () => {
      const file = input && input.files ? input.files[0] : null;
      if (!file) {
        return;
      }

      try {
        const { data } = await uploadNoticeImage(file);
        if (data.code === 200) {
          const imgUrl = data.pathUrl;
          if (!quillRef.current) {
            return;
          }

          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();

          if (!range) {
            return;
          }

          editor.insertEmbed(range.index, 'image', imgUrl);
          editor.setSelection(range.index + 1, 0);
          // 이미지 등록 시 url 저장
          onUploadImage(imgUrl);
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const formats = [
    'font',
    'size',
    'header',
    'color',
    'background',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'link',
    'image',
  ];
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ size: [] }], // custom dropdown
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          ['link', 'image'],
        ],
        handlers: { image: imageHandler },
      },
    }),
    [],
  );

  return (
    <ReactQuill
      ref={quillRef}
      formats={formats}
      modules={modules}
      onChange={onChange}
      defaultValue={value}
      className={'max-h-page35'}
    />
  );
}
