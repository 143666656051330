import { Link } from 'react-router-dom';

import CommunicationList from 'components/locker-status/communication/CommunicationList';

export default function Communication() {
  return (
    <div className={'contents'} data-simplebar>
      <nav>
        <ol className={'breadcrumb'}>
          <li className={'breadcrumb-item'}>
            <Link to={'/home'} replace>
              {'Home'}
            </Link>
          </li>
          <li className={'breadcrumb-item active'} aria-current={'page'}>
            {'통신 현황'}
          </li>
        </ol>
      </nav>
      <CommunicationList />
    </div>
  );
}
