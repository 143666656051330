import { Children } from 'react';

import { Locking } from 'model/Locking';

type LotoOrderConclusionTableProps = {
  dataList: Locking[];
  onOpenModal?: (id: number) => void;
};

export default function LotoOrderConclusionTable({
  dataList,
  onOpenModal = (id: number) => {},
}: LotoOrderConclusionTableProps) {
  return (
    <table className={'tbl-type-04 no-img'}>
      <caption>{'조회내역'}</caption>
      <colgroup>
        <col width={10} />
        <col />
        <col />
        <col />
        <col width={100} />
        <col />
        <col />
        <col />
      </colgroup>
      <thead>
        <tr>
          <th>{'No.'}</th>
          <th>{'Locker'}</th>
          <th>{'설비 명'}</th>
          <th>{'부착자'}</th>
          <th>{'전화번호'}</th>
          <th>{'부착일시'}</th>
          <th>{'제거자'}</th>
          <th>{'제거일시'}</th>
        </tr>
      </thead>
      <tbody>
        {Children.toArray(
          dataList.map((conclusion, index) => (
            <tr>
              <td className={'ta-center'}>{index + 1}</td>
              <td>{conclusion.lockerName ?? '-'}</td>
              <td>
                {`[${conclusion.equipCode}]`}
                &nbsp;
                {conclusion.equipName ?? '-'}
              </td>
              <td className={'ta-center'}>{conclusion.lockUserName ?? '-'}</td>
              <td className={'ta-center'}>{conclusion.lockUserPhone ?? '-'}</td>
              <td className={'ta-center'}>{conclusion.lockRegDate ?? '-'}</td>
              <td className={'ta-center'}>
                {conclusion.unlockUserName ?? '-'}
              </td>
              <td className={'ta-center'}>{conclusion.unlockRegDate ?? '-'}</td>
            </tr>
          )),
        )}
      </tbody>
    </table>
  );
}
