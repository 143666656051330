import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getFactoryList } from 'api/customerApi';
import { customerKeys, factoryKeys } from 'api/queryKeys/queryKeys';
import { SearchRequest } from 'api/request/SearchRequest';
import { signOut } from 'api/sessionApi';
import { getCustomerList } from 'api/userApi';
import { Button } from 'components/widget/Button';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { searchInfoState, userInfoState } from 'recoil/user/atom';
import { ROLE_TYPE } from 'types/enumType';
import { isSuperRole } from 'util/commonUtil';
import { convertRoleToString } from 'util/stringUtil';

import { decryptString } from '../util/encryptUtil';

export default function Header() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const resetUserInfo = useResetRecoilState(userInfoState);
  const resetSearchInfo = useResetRecoilState(searchInfoState);
  const [searchRequest, setSearchRequest] = useState<SearchRequest>(
    new SearchRequest(1, 9999),
  );

  const handleChangeInfo = () => {
    navigate('/mypage');
  };

  const handleSignOut = async () => {
    await signOut();
    resetUserInfo();
    queryClient.clear();
    sessionStorage.clear();
  };

  const isSuper = isSuperRole(decryptString(userInfo.role));

  const { data: customerData, isLoading: isLoadingCustomer } = useQuery({
    queryKey: customerKeys.all,
    queryFn: () => getCustomerList(searchRequest),
    enabled: isSuper,
  });

  const {
    data: factoryData,
    refetch: refetchFactory,
    isLoading: isLoadingFactory,
  } = useQuery({
    queryKey: factoryKeys.list(Number(userInfo.customerId)),
    queryFn: () => getFactoryList(Number(userInfo.customerId)),
    enabled: false,
  });

  const onChangeCustomer = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const customerName =
      (customerData?.list ?? []).find(
        (customer) => customer.customerId === Number(value ?? -1),
      )?.customerName ?? '';
    setUserInfo((prev) => ({
      ...prev,
      customerId: value,
      customerName,
      factoryId: '',
      factoryName: '',
      areaUseYn: '',
    }));
    resetSearchInfo();
  };

  const getFactoryById = (id: number) =>
    (factoryData?.list ?? []).find((factory) => factory.factoryId === id);

  const onChangeFactory = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const targetFactory = getFactoryById(Number(value))!;
    setUserInfo((prev) => ({
      ...prev,
      factoryId: value,
      factoryName: targetFactory.name,
      areaUseYn: targetFactory?.areaUseYn,
    }));
    resetSearchInfo();
  };

  useEffect(() => {
    if (userInfo.customerId) {
      if (decryptString(userInfo.role) !== ROLE_TYPE.USER) {
        refetchFactory();
      }
    }
  }, [userInfo.customerId]);

  useEffect(() => {
    if (!customerData) {
      return;
    }

    const customerList = customerData?.list ?? [];
    const isExistCustomer = customerList.some(
      (customer) => customer.customerId === Number(userInfo?.customerId ?? -1),
    );

    if (customerList && customerList?.length > 0 && !isExistCustomer) {
      const { customerId, name } = customerList[0];
      setUserInfo((prev) => ({
        ...prev,
        customerId: customerId.toString(),
        customerName: name,
        factoryId: '',
        factoryName: '',
        areaUseYn: '',
      }));
    }
  }, [customerData]);

  useEffect(() => {
    if (!factoryData) {
      return;
    }

    const factoryList = factoryData?.list ?? [];
    const isExistFactory = factoryList.some(
      (factory) => factory.factoryId === Number(userInfo?.factoryId ?? -1),
    );

    if (factoryList && factoryList.length > 0 && !isExistFactory) {
      setUserInfo((prev) => ({
        ...prev,
        factoryId: factoryList[0].factoryId.toString(),
        factoryName: factoryList[0].name,
        areaUseYn: factoryList[0].areaUseYn,
      }));
    }
  }, [factoryData]);

  return (
    <header className={'header'}>
      <h1 className={'logo-title'}>
        <i className={'logo'} />
        <div className={'select-wrap'}>
          {isSuperRole(decryptString(userInfo.role)) ? (
            <select
              className={'input-box-type-01'}
              value={userInfo.customerId}
              onChange={onChangeCustomer}
            >
              {!isLoadingCustomer &&
                customerData?.list?.map((customer) => (
                  <option value={customer.customerId} key={customer.customerId}>
                    {customer.name}
                  </option>
                ))}
            </select>
          ) : (
            <p>{userInfo.customerName}</p>
          )}
        </div>
        <div className={'select-wrap'}>
          {decryptString(userInfo.role) === ROLE_TYPE.USER ? (
            <p>{userInfo.factoryName}</p>
          ) : (
            <select
              className={'input-box-type-01'}
              value={userInfo.factoryId}
              onChange={onChangeFactory}
            >
              {!isLoadingFactory &&
                factoryData?.list?.map((factory) => (
                  <option value={factory.factoryId} key={factory.factoryId}>
                    {factory.name}
                  </option>
                ))}
            </select>
          )}
        </div>
      </h1>
      <section className={'header-right-section'}>
        <div className={'header-info'}>
          <label
            htmlFor={'user-name'}
          >{`${convertRoleToString(decryptString(userInfo.role))}: `}</label>
          <span className={'user-name'} id={'user-name'}>
            {userInfo.name}
          </span>
          <div className={'btn-group no-bg'}>
            <button
              type={'button'}
              className={'p-right btn-type-01'}
              onClick={handleChangeInfo}
            >
              {'정보변경'}
            </button>
            <Button
              name={'로그아웃'}
              className={'p-right btn-type-02'}
              onClick={handleSignOut}
            />
          </div>
        </div>
      </section>
    </header>
  );
}
