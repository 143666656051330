import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import qs from 'qs';

import { ChangeHistory } from 'model/ChangeHistory';
import { Customer } from 'model/Customer';
import Locker from 'model/Locker';
import { User } from 'model/User';
import { ROLE_TYPE, YN_TYPE } from 'types/enumType';

import { BaseResp } from './BaseResp';
import { client } from './client';
import { downloadExcel } from './commonApi';
import { CustomerSearchRequest } from './request/CustomerSearchRequest';
import { LogSearchRequest } from './request/LogSearchRequest';
import { SearchRequest } from './request/SearchRequest';
import { UserSearchRequest } from './request/UserSearchRequest';

export interface UserFormData {
  customerId?: number;
  id: number;
  account: string;
  name: string;
  role: ROLE_TYPE | string;
  phone: string;
  email: string;
  activate: YN_TYPE | string;
  resetPass?: boolean | string;
  isDoubleCheck?: boolean;
}

export interface UserAuthFormData {
  userId: number;
  lockerIds: number[];
}

export interface MyInfoFormData {
  account: string;
  name: string;
  phone: string;
}

export interface PassFormData {
  oldPass: string;
  newPass: string;
}

export interface UserListResp extends BaseResp {
  totalCount: number;
  list: User[];
}

export interface UserChangeHistoryResp extends BaseResp {
  totalCount: number;
  list: ChangeHistory[];
}

export interface UserResp extends BaseResp {
  info: User;
}

export interface AuthListResp extends BaseResp {
  lockers: Locker[];
  name: string;
  phone: string;
}

export interface PermissionListResp extends BaseResp {
  auths: Locker[];
  lockers: Locker[];
  name: string;
  phone: string;
}

export interface CustomerListResp extends BaseResp {
  list: Customer[];
  totalCount: number;
}

// User API
export const getUserList = (req: UserSearchRequest): Promise<UserListResp> =>
  client.get('v1/user/list', { params: req });
export const getUser = (lockerId: number): Promise<UserResp> =>
  client.get(`v1/user/${lockerId}`);
const registerUser = (formData: UserFormData): Promise<BaseResp> =>
  client.post('v1/user/regist', formData);
const updateUser = (user: UserFormData): Promise<BaseResp> =>
  client.post(`v1/user/${user.id}`, { ...user });
export const getUserChangeHistory = (
  req: LogSearchRequest,
): Promise<UserChangeHistoryResp> =>
  client.get(`v1/user/${req.id}/log`, { params: req });

export const useRegisterUserMutation = () =>
  useMutation({ mutationFn: registerUser });
export const useUpdateUserMutation = () =>
  useMutation({ mutationFn: updateUser });
export const getPermissionListByUser = (userId: number): Promise<PermissionListResp> =>
  client.get(`v1/auth/${userId}/locker`);
export const getMyInfo = (): Promise<UserResp> => client.get('v1/account/info');
export const downloadUserListExcel = (req: UserSearchRequest) =>
  downloadExcel(req, 'v1/user/excel', '사용자목록');

// MY PAGE
const updateMyInfo = (user: MyInfoFormData): Promise<BaseResp> =>
  client.post('/v1/account/info', { ...user });
const changePassword = (formData: PassFormData): Promise<BaseResp> =>
  client.post('v1/account/pass', { ...formData });
const checkAccount = (account: string): Promise<BaseResp> =>
  client.post('/v1/user/check', { account });
const registerSignImg = (image: string): Promise<BaseResp> =>
  client.post('v1/account/sign', { image });

export const useUpdateMyInfoMutation = () =>
  useMutation({ mutationFn: updateMyInfo });
export const useChangePasswordMutation = () =>
  useMutation({ mutationFn: changePassword });
export const useCheckAccountMutation = () =>
  useMutation({ mutationFn: checkAccount });
export const useRegisterSignImgMutation = () => useMutation({ mutationFn: registerSignImg });

// 권한
export const getAuthListByUser = (userId: number): Promise<AuthListResp> =>
  client.get(`v1/auth/${userId}/locker`, { params: { activate: YN_TYPE.Y } });
const updateAuthByUser = (formData: UserAuthFormData) =>
  axios({
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/auth/${formData.userId}`,
    method: 'POST',
    data: qs.stringify(
      { lockerIds: [...formData.lockerIds] },
      { arrayFormat: 'repeat' },
    ),
    withCredentials: true,
  });

export const useUpdateAuthByUserMutation = () =>
  useMutation({ mutationFn: updateAuthByUser });

// Customer API
export const getCustomerList = (req: CustomerSearchRequest | SearchRequest): Promise<CustomerListResp> => client.get('/v1/customer/list', { params: req });
export const getCustomerListBySession = (): Promise<CustomerListResp> => client.get('/v1/customer/list');

// Admin API
export const getAdminList = (req: UserSearchRequest): Promise<UserListResp> => client.get('v1/user/admin', { params: req });
