import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import ErrorMessage from 'components/widget/ErrorMessage';
import Image from 'components/widget/Image';
import { User } from 'model/User';

import { MyInfoFormData } from '../../api/userApi';
import { mypageUpdateSchema } from './resolvers/MypageResolvers';

type UserInfoFormProps = {
  item: User | undefined;
  onPasswordModalClick: () => void;
  onSignModalClick: () => void;
  onSubmit: (formData: MyInfoFormData) => void;
};

function UserInfoForm({
  item,
  onPasswordModalClick,
  onSignModalClick,
  onSubmit,
}: UserInfoFormProps) {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: mypageUpdateSchema });

  useEffect(() => {
    reset(item);
  }, [item, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>{'내정보'}</h2>
      <div className={'table-wrap mt-40'}>
        <table className={'tbl-type-05 input-design input-small '}>
          <caption>{'관리자정보'}</caption>
          <colgroup>
            <col width={'20%'} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <p>{'아이디'}</p>
              </th>
              <td>
                <input
                  type={'text'}
                  className={'input-box-type-01'}
                  {...register('account')}
                  disabled
                  maxLength={50}
                />
              </td>
            </tr>
            <tr>
              <th>
                <p>{'이름'}</p>
              </th>
              <td>
                <input
                  type={'text'}
                  className={'input-box-type-01'}
                  {...register('name')}
                  maxLength={50}
                />
              </td>
            </tr>
            <tr>
              <th>
                <p>{'비밀번호'}</p>
              </th>
              <td>
                <div className={'input-box-group'}>
                  <button
                    type={'button'}
                    className={'btn-type-04 w15rem'}
                    onClick={onPasswordModalClick}
                  >
                    {'비밀번호변경'}
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <p>{'휴대폰번호'}</p>
              </th>
              <td>
                <input
                  type={'text'}
                  className={'input-box-type-01'}
                  {...register('phone')}
                  maxLength={50}
                />
              </td>
            </tr>
            <tr>
              <th>
                <p>{'이메일'}</p>
              </th>
              <td>
                <input
                  type={'text'}
                  className={'input-box-type-01'}
                  {...register('email')}
                  maxLength={50}
                />
              </td>
            </tr>
            <tr>
              <th>{'서명 이미지'}</th>
              <td>
                <Image
                  src={item?.signImage}
                  alt={'서명이미지'}
                  width={200}
                  height={100}
                />
                <div>
                  <button
                    type={'button'}
                    className={'btn-type-01'}
                    onClick={onSignModalClick}
                  >
                    {'등록'}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ErrorMessage errors={errors} />
      </div>
      <div className={'btn-group no-bg input-large mt-40'}>
        <button type={'submit'} className={'btn-type-01'}>
          {'수정'}
        </button>
      </div>
    </form>
  );
}

export default UserInfoForm;
