export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 0,
  },
};

export const generatePopupContent = (content: string): string[] => [
  '<html><head><title></title>',
  `
    <link rel="stylesheet" href="/assets/css/setup.css">
    <link rel="stylesheet" href="/assets/css/jquery-ui.css">
    <link rel="stylesheet" href="/assets/css/simplebar.min.css">`,
  '</head><body>',
  content,
  '</body></html>',
]