import { useMutation } from '@tanstack/react-query';

import { Place } from 'model/Place';
import Statistic from 'model/Statistic';
import { User } from 'model/User';

import { BaseResp } from './BaseResp';
import { client } from './client';

export type Account = {
  account: string;
  passwd: string;
};

interface SignInResp extends BaseResp {
  info: User;
  customerId: number;
}

interface DashboardResp extends BaseResp {
  areas: Place[];
  statistics: Statistic;
}

const signIn = (account: Account): Promise<SignInResp> => client.post('/v1/account/login', { ...account });
export const useSignInMutation = () => useMutation({ mutationFn: signIn });

export const signOut = () => client.delete('/v1/account/logout');

const checkPassword = (passwd: string): Promise<BaseResp> => client.post('/v1/account/check-pass', { passwd });
export const useCheckPasswordMutation = () => useMutation({ mutationFn: checkPassword });

// home
export const getStatistic = (factoryId: number): Promise<DashboardResp> => client.get('/v1/home/statistics', { params: factoryId });
export const getDashboard = (factoryId: number): Promise<DashboardResp> => client.get('/v1/home/dashboard', { params: factoryId });