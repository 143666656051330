import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';

import Image from 'components/widget/Image';
import { Log } from 'model/Log';
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/user/atom';
import { LOCKER_STATE } from 'types/enumType';
import { isSuperRole } from 'util/commonUtil';
import { decryptString } from 'util/encryptUtil';
import { columnAlignByName } from 'util/tableUtil';

type CommunicationTableProps = {
  dataList: Log[];
  page: number;
  count: number;
  onClickDetail: (column: string, id: number) => void;
  onOpenQrModal?: (img: string) => void;
};

export default function CommunicationTable({
  dataList,
  page,
  count,
  onClickDetail,
  onOpenQrModal = () => {},
}: CommunicationTableProps) {
  const { role } = useRecoilValue(userInfoState);
  const columnHelper = createColumnHelper<Log>();
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'regDate', desc: true },
  ]);

  const getRoutingElement = useCallback(
    (key: string, id: number, name: string) => {
      const isSuper = isSuperRole(decryptString(role));

      return isSuper || key === 'lockerName' ? (
        <div onClick={() => onClickDetail(key, id)} className={'table-id'}>
          {name}
        </div>
      ) : (
        `${name}`
      );
    },
    [],
  );

  const getQrIconElement = (image: string, state: LOCKER_STATE) => {
    if (!image) {
      return '';
    }

    return (
      <div className={'flex-center'} onClick={() => onOpenQrModal(image)}>
        <p>{state}</p>
        <Image
          alt={'qrIcon'}
          src={`${process.env.PUBLIC_URL}/assets/images/qr-icon.png`}
          key={image}
          width={25}
          className={'ml-10'}
        />
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      columnHelper.accessor('regUserName', {
        cell: (info) =>
          getRoutingElement(
            info.column.id,
            Number(info.row.id),
            info.getValue(),
          ),
        header: '사용자',
      }),
      columnHelper.accessor('lockerName', {
        cell: (info) =>
          getRoutingElement(
            info.column.id,
            Number(info.row.original.lockerId),
            info.getValue(),
          ),
        header: 'Locker',
      }),
      columnHelper.accessor('macAddr', {
        cell: (info) => info.getValue(),
        header: 'MAC Address',
      }),
      columnHelper.accessor('image', {
        cell: (info) =>
          info.getValue()
            ? getQrIconElement(info.getValue(), info.row.original.state)
            : info.row.original.state,
        header: '이벤트',
      }),
      columnHelper.accessor('result', {
        cell: (info) => info.getValue(),
        header: '내용',
      }),
      columnHelper.accessor('regDate', {
        cell: (info) => info.getValue(),
        header: '등록일시',
      }),
    ],
    [columnHelper],
  );

  const table = useReactTable({
    data: dataList,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (originalRow) => originalRow.regUserId.toString(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  return (
    <table className={'tbl-type-04 no-img'}>
      <colgroup>
        <col />
        <col />
        <col width={'10%'} />
        <col width={'8%'} />
        <col />
        <col width={'13%'} />
      </colgroup>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={`lockerTheadTr${headerGroup.id}`}>
            {headerGroup.headers.map((header) => (
              <th key={`lockerTheadTh${header.id}`}>
                {header.isPlaceholder ? null : (
                  <button
                    className={
                      header.column.getCanSort()
                        ? 'cursor-pointer select-none'
                        : ''
                    }
                    onClick={header.column.getToggleSortingHandler()}
                    type={'button'}
                    aria-label={'btn'}
                    title={
                      header.column.getCanSort()
                        ? header.column.getNextSortingOrder() === 'asc'
                          ? 'Sort ascending'
                          : header.column.getNextSortingOrder() === 'desc'
                            ? 'Sort descending'
                            : 'Clear sort'
                        : undefined
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {{
                      asc: ' ▲',
                      desc: ' ▼',
                    }[header.column.getIsSorted() as string] ?? null}
                  </button>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {dataList.length === 0 && (
          <tr>
            <td colSpan={tableColumns.length} className={'center'}>
              {'데이터가 없습니다.'}
            </td>
          </tr>
        )}
        {table.getRowModel().rows.map((row) => (
          <tr key={`tbodyTr${row.original.regDate}`}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={`tbodyTd${cell.id}`}
                className={columnAlignByName(cell.column.id)}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
