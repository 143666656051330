import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ROLE_TYPE, YN_TYPE } from 'types/enumType';
import { SidebarItemType } from 'types/sidebar';

import SidebarNavItem from './SidebarNavItem';

type SidebarNavDepthItemProps = {
  item: SidebarItemType;
  userRole: string;
  areaUseYn: string;
  className: string;
};

export default function SidebarNavDepthItem({
  item,
  userRole,
  areaUseYn,
  className,
}: SidebarNavDepthItemProps) {
  const location = useLocation();
  const [onOpen, setOnOpen] = useState(false);

  useEffect(() => {
    setOnOpen(item.href.split('/')[1] === location.pathname.split('/')[1]);
  }, [location.pathname]);

  return (
    <li className={'depth-01'} key={item.href}>
      <button
        type={'button'}
        className={`depth-more ${onOpen ? 'on' : ''}`}
        onClick={() => setOnOpen(!onOpen)}
      >
        {item.name}
      </button>
      <ul className={`depth-02-wrap ${onOpen ? 'active' : ''}`}>
        {item.children &&
          item.children.map((childItem) => {
            if (
              childItem.href === '/users/customer' &&
              !(
                userRole === ROLE_TYPE.SUPER_MANAGER ||
                userRole === ROLE_TYPE.SUPER_ADMIN
              )
            ) {
              return null;
            }

            if (
              childItem.href === '/users/admin' &&
              userRole !== ROLE_TYPE.SUPER_ADMIN
            ) {
              return null;
            }

            if (
              areaUseYn === YN_TYPE.N &&
              childItem.href === '/device/area-position'
            ) {
              return null;
            }

            return (
              <SidebarNavItem
                key={childItem.href}
                item={childItem}
                className={'depth-02'}
              />
            );
          })}
      </ul>
    </li>
  );
}
