import { useForm } from 'react-hook-form';

import { LogSearchRequest } from 'api/request/LogSearchRequest';
import { LOCKER_STATE } from 'types/enumType';

type CommunicationSearchBoxProps = {
  searchRequest: LogSearchRequest;
  isLoading: boolean;
  onSubmit: (req: LogSearchRequest) => void;
};

export default function CommunicationSearchBox({
  searchRequest,
  isLoading,
  onSubmit,
}: CommunicationSearchBoxProps) {
  const { register, handleSubmit } = useForm<LogSearchRequest>({
    defaultValues: {
      startDate: searchRequest.startDate,
      endDate: searchRequest.endDate,
      event: searchRequest.event,
    },
  });

  return (
    <section>
      <h2>{'통신 현황'}</h2>
      <div className={'search-box-wrap mt-10'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={'search-grid gtype-03'}>
            <article className={'search-card'}>
              <span className={'label-text-02'}>{'조회날짜'}</span>
              <div className={'d-wrap'}>
                <input
                  type={'date'}
                  className={'datepicker input-box-type-01'}
                  {...register('startDate')}
                />
              </div>
              <span className={'dasi'} />
              <div className={'d-wrap'}>
                <input
                  type={'date'}
                  className={'datepicker input-box-type-01'}
                  {...register('endDate')}
                />
              </div>
            </article>
          </div>
          <div className={'search-grid gtype-03'}>
            <article className={'search-card'}>
              <span className={'label-text-02'}>{'이벤트'}</span>
              <select className={'input-box-type-01'} {...register('event')}>
                <option value={''}>{'전체'}</option>
                <option value={LOCKER_STATE.CONNECT}>
                  {LOCKER_STATE.CONNECT}
                </option>
                <option value={LOCKER_STATE.LOCKED}>
                  {LOCKER_STATE.LOCKED}
                </option>
                <option value={LOCKER_STATE.UNLOCK}>
                  {LOCKER_STATE.UNLOCK}
                </option>
              </select>
            </article>
            <article className={'search-card'}>
              <p className={'label-text-02'}>{'검색어'}</p>
              <input
                type={'text'}
                className={'input-box-type-01'}
                defaultValue={searchRequest.searchKey}
                {...register('searchKey')}
                placeholder={'사용자/Locker 명/MAC Address'}
                maxLength={50}
              />
            </article>
            <button type={'submit'} className={'p-right btn-type-01'}>
              {'검색/갱신'}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}
