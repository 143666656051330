import { Link } from 'react-router-dom';

import MypageComponent from '../components/mypage/Mypage';

export default function Mypage() {
  return (
    <div className={'contents'} data-simplebar>
      <nav>
        <ol className={'breadcrumb'}>
          <li className={'breadcrumb-item'}>
            <Link to={'/home'} replace>
              {'Home'}
            </Link>
          </li>
          <li className={'breadcrumb-item active'} aria-current={'page'}>
            {'정보관리'}
          </li>
        </ol>
      </nav>
      <MypageComponent />
    </div>
  );
}
