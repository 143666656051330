import { SidebarItemType } from 'types/sidebar';

const dashboardItemList: SidebarItemType[] = [
  {
    href: '/home',
    title: 'Home',
    name: 'Home',
  },
  {
    href: '/lock-status/order',
    title: '작업허가서',
    name: '작업허가서',
    children: [
      {
        href: '/lock-status/order',
        title: '작업허가서',
        name: '작업허가서',
      },
      {
        href: '/lock-status/conclusion',
        title: '부착 현황',
        name: '부착 현황',
      },
      {
        href: '/lock-status/communication',
        title: '통신 현황',
        name: '통신 현황',
      },
    ],
  },
  {
    href: '/locker',
    title: 'Locker 관리',
    name: 'Locker 관리',
    children: [
      {
        href: '/locker',
        title: 'Locker 관리',
        name: 'Locker 관리',
      },
    ]
  },
  {
    href: '/device/setting',
    title: '설비관리',
    name: '설비관리',
    children: [
      {
        href: '/device/setting',
        title: '설비관리',
        name: '설비관리',
      },
      {
        href: '/device/area-position',
        title: 'Area 배치 관리',
        name: 'Area 배치 관리',
      },
      {
        href: '/device/device-position',
        title: '설비 배치 관리',
        name: '설비 배치 관리',
      },
    ],
  },
  {
    href: '/users/user',
    title: '사용자 관리',
    name: '사용자 관리',
    children: [
      {
        href: '/users/user',
        title: '사용자 관리',
        name: '사용자 관리',
      },
      {
        href: '/users/customer',
        title: '고객사 관리',
        name: '고객사 관리',
      },
      {
        href: '/users/admin',
        title: '관리자 관리',
        name: '관리자 관리',
      },
    ],
  },
  {
    href: '/board',
    title: '사이트 관리',
    name: '사이트 관리',
    children: [
      {
        href: '/board/notice',
        title: '공지사항',
        name: '공지사항',
      },
      {
        href: '/board/inquiry',
        title: '고객 문의',
        name: '고객 문의',
      },
    ],
  },
];
export default dashboardItemList;

