import { useQuery } from '@tanstack/react-query';
import { differenceInCalendarDays } from 'date-fns';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { downloadLogsListExcel, getLogList } from 'api/logApi';
import { logKeys } from 'api/queryKeys/queryKeys';
import { LogSearchRequest } from 'api/request/LogSearchRequest';
import CommunicationSearchBox from 'components/searchbox/CommunicationSearchBox';
import PageCountBox from 'components/widget/PageCountBox';
import Pagination from 'components/widget/Pagination';
import { useRecoilState, useRecoilValue } from 'recoil';
import { searchInfoState, userInfoState } from 'recoil/user/atom';

import CommunicationTable from './CommunicationTable';
import DeviceQrModal from './DeviceQrModal';

export default function LogList() {
  const navigate = useNavigate();
  const { factoryId } = useRecoilValue(userInfoState);
  const location = useLocation();
  const [searchInfo, setSearchInfo] = useRecoilState(searchInfoState);

  const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [searchRequest, setSearchRequest] = useState<LogSearchRequest>(() =>
    searchInfo.location === location.pathname
      ? (searchInfo.search as LogSearchRequest)
      : new LogSearchRequest(1, 20, Number(factoryId ?? -1)),
  );
  const { data, isLoading, refetch } = useQuery({
    queryKey: logKeys.list(searchRequest),
    queryFn: () => getLogList(searchRequest),
  });

  useEffect(() => {
    setSearchInfo({
      location: location?.pathname ?? '',
      search: searchRequest,
    });
  }, [searchRequest]);

  useEffect(() => {
    const id = Number(factoryId) ?? -1;
    if (id > 0) {
      setSearchRequest((prev) => ({ ...prev, factoryId: id }));
    }
  }, [factoryId]);

  const handleSearchSubmit = (formData: LogSearchRequest) => {
    if (
      formData.startDate &&
      formData.endDate &&
      differenceInCalendarDays(
        new Date(formData.startDate),
        new Date(formData.endDate),
      ) > 0
    ) {
      alert('시작일이 종료일보다 큽니다');

      return;
    }

    setSearchRequest((prev) => ({
      ...prev,
      ...formData,
      page: 1,
    }));

    setTimeout(() => refetch());
  };

  const onOpenQrModal = (url: string) => {
    setImageUrl(url);
    setIsQrModalOpen(true);
  };

  const onCloseQrModal = () => {
    setImageUrl('');
    setIsQrModalOpen(false);
  };

  const onClickDetail = (column: string, id: number) => {
    const path = column === 'lockerName' ? '/locker' : '/users/user';
    navigate(`${path}/${id}`);
  };

  const onChangePage = (page: number) => {
    setSearchRequest((prev) => ({ ...prev, page }));
  };

  const downloadExcel = () => {
    if (!data?.list || data?.list.length === 0) {
      alert('데이터가 없습니다.');
      return;
    }

    downloadLogsListExcel(searchRequest);
  };

  const onChangePageSize = (e: ChangeEvent<HTMLSelectElement>) => {
    setSearchRequest((prev) => ({
      ...prev,
      page: 1,
      count: Number(e.target.value),
    }));
  };

  return (
    <>
      <CommunicationSearchBox
        searchRequest={searchRequest}
        isLoading={isLoading}
        onSubmit={handleSearchSubmit}
      />
      <section className={'mt-20'}>
        <div className={'table-wrap'}>
          <div className={'table-wrap-title'}>
            <article className={'left-info-wrap input-small-p-left'}>
              <h2>{'조회내역'}</h2>
              <p className={'label-text-02'}>
                {data?.totalCount ?? 0} {'건'}
              </p>
            </article>
            <article className={'right-info-wrap input-small p-right'}>
              <p className={'label-text-02'}>{'조회건수'}</p>
              <PageCountBox
                pageCount={searchRequest.count}
                onChangePageCount={onChangePageSize}
              />
              {/* <Button
                name={'엑셀다운로드'}
                className={'btn-type-03'}
                onClick={downloadExcel}
              /> */}
            </article>
          </div>
          <CommunicationTable
            dataList={data?.list ?? []}
            page={searchRequest.page}
            count={searchRequest.count}
            onClickDetail={onClickDetail}
            onOpenQrModal={onOpenQrModal}
          />
          <Pagination
            page={searchRequest.page}
            totalCount={data?.totalCount ?? 0}
            count={searchRequest.count}
            onPageClick={onChangePage}
          />
        </div>
      </section>
      {isQrModalOpen && (
        <DeviceQrModal
          src={imageUrl}
          isOpen={isQrModalOpen}
          onClose={onCloseQrModal}
        />
      )}
    </>
  );
}
