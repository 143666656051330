export class SearchRequest {
  searchKey: string = '';

  page: number;

  count: number;

  constructor(page: number, count: number) {
    this.page = page;
    this.count = count;
  }
}